import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { ChatComponent } from './chat/chat.component';
import { CallbackComponent } from './callback/callback.component';

const routes: Routes = [
  { path: '', redirectTo: 'chat/bbpm', pathMatch:'full' },
  { path: 'oauth2/callback', component: CallbackComponent},
  { path: 'chat/:tenant_id', component: ChatComponent},
  { path: 'chat/:tenant_id/:subject_id', component: ChatComponent},
  { path: 'admin', component: AdminComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
