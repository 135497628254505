export default function contains(parent, child) {
  var rootNode = child.getRootNode && child.getRootNode(); // $FlowFixMe: Node is not aware of host

  var isShadow = Boolean(rootNode && rootNode.host); // First, attempt with faster native method

  if (parent.contains(child)) {
    return true;
  } // then fallback to custom implementation with Shadow DOM support
  else if (isShadow) {
    var next = child;
    do {
      if (next && parent.isSameNode(next)) {
        return true;
      } // $FlowFixMe: need a better way to handle this...

      next = next.parentNode || next.host;
    } while (next);
  } // Give up, the result is false

  return false;
}