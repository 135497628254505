<div class="chat-container page-container">
  <div class="d-flex flex-column h-100">
    <div class="header p-4 shadow-sm d-flex align-items-center">
      <img class="logo me-4" src="./../../assets/images/logo.svg" />
      <h2 class="mb-0 text-white">Consultazione normativa</h2>
    </div>
    <div class="row h-100 bot-container">
      <!-- Chat List -->
      <div class="col-md-4 h-100 px-0">
        <div class="d-flex flex-column h-100">
          <div
            class="py-2 px-4 shadow-lg d-flex align-items-center justify-content-between"
          >
            <h3 class="mb-0">Threads</h3>
            <button
              (click)="newThread()"
              class="btn btn-rounded-full btn-primary"
            >
              Nuovo Thread <i class="ms-1 bi bi-pencil"></i>
            </button>
          </div>
          <div class="bg-white h-100 overflow-auto py-2 px-4">
            <button
              (click)="showThread(thread)"
              *ngFor="let thread of threads"
              class="btn rounded p-0 w-100 mb-2"
              [class]="{
                'btn-primary': current_thread_id === thread.thread_id,
                'btn-light': current_thread_id !== thread.thread_id
              }"
            >
              <div class="text-start p-2 rounded shadow-sm">
                <div class="d-flex justify-content-between">
                  <h4 [ngbTooltip]="thread.topic" class="text-truncate">
                    {{ thread.topic }}
                  </h4>
                  <div class="ms-2">
                    <small class="text-nowrap" [ngbTooltip]="formattedThreadTime(thread.time)">{{
                      humanizedThreadTime(thread.time)
                    }}</small>
                  </div>
                </div>
                <label [ngbTooltip]="threadSubject(thread)" class="text-truncate">
                  {{ threadSubject(thread) }}
                </label>
              </div>
            </button>
          </div>
        </div>
      </div>
      <!-- Chat -->
      <div class="col-md-8 h-100 px-0">
        <div class="d-flex flex-column h-100">
          <div class="chat-header p-4 shadow-sm">
            <div class="row">
              <div class="col-md-8">
                <small>Cerca in:</small>
                <ng-select
                  [(ngModel)]="selectedSubjectId"
                  placeholder="Seleziona un ambito"
                  [clearable]="false"
                  [disabled]="current_thread_id !== ''"
                >
                  <ng-option
                    *ngFor="let subject of subjects"
                    [value]="subject.subject_id"
                  >
                    {{ subject.name }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="col-md-4">
                <small>AI Engine:</small>
                <ng-select [(ngModel)]="ai_model" [clearable]="false">
                  <ng-option
                    *ngFor="let aimodel of ai_models"
                    [value]="aimodel.id"
                  >
                    {{ aimodel.name }}
                  </ng-option>
                </ng-select>
              </div>
            </div>
          </div>
          <div
            #scrollMe
            class="chat-content bg-light h-100 overflow-auto px-4 py-2"
          >
            <div *ngIf="selectedSubjectId === 'all' && !current_thread_id" class="bg-dark text-white px-4 py-2 rounded mb-4">
              <small class="mb-0"><i class="bi bi-info-circle me-1"></i>Seleziona un ambito per ricevere informazioni più precise alle tue domande</small>
            </div>
            <div
              class="message mt-4"
              *ngFor="let message of messages; index as midx"
            >
              <ng-container *ngIf="message.isBot">
                <div class="d-flex justify-content-start mb-4 isBot" [class]="{'highlight': highlightMessageIdx === midx}">
                  <div class="avatar-container me-2">
                    <div
                      class="avatar d-flex justify-content-center align-items-center"
                    >
                      <i class="text-white bi bi-robot"></i>
                    </div>
                  </div>
                  <div class="message bg-white p-4 rounded shadow-sm w-75">
                    <small
                      [ngbTooltip]="formattedThreadTime(message.date)"
                      class="text-muted"
                      >{{ humanizedThreadTime(message.date) }}</small
                    >
                    <!-- FAQ Answer -->
                    <div
                      class="mb-2 pt-2"
                      *ngIf="message.faq && message.thread_id"
                    >
                      <h5 class="mb-2">FAQ suggerita per la tua domanda:</h5>
                      <div
                        class="accordion"
                        [id]="'faq_accordion_' + message.question_id"
                      >
                        <div class="accordion-item">
                          <h4 class="accordion-header mb-0" id="headingOne">
                            <button
                              class="accordion-button p-2"
                              [ngClass]="{
                                '': !message.content.content,
                                collapsed: message.content.content
                              }"
                              type="button"
                              data-bs-toggle="collapse"
                              [attr.data-bs-target]="
                                '#' +
                                'faq_accordion_' +
                                message.question_id +
                                'one'
                              "
                              [attr.aria-expanded]="
                                !message.content.content ? true : false
                              "
                              aria-controls="collapseOne"
                            >
                              {{ message.faq.question }}
                            </button>
                          </h4>
                          <div
                            [id]="
                              'faq_accordion_' + message.question_id + 'one'
                            "
                            class="accordion-collapse"
                            [ngClass]="{
                              show: !message.content.content,
                              collapse: message.content.content
                            }"
                            aria-labelledby="headingOne"
                            [attr.data-bs-parent]="
                              '#' + 'faq_accordion_' + message.question_id
                            "
                          >
                            <div class="accordion-body">
                              {{ message.faq.answer }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        *ngIf="
                          !message.content.content && (midx === messages.length-1)
                        "
                        class="card-footer text-muted mt-3 d-flex align-items-center"
                      >
                        <span
                          >Se questa FAQ non ha risposto alla tua domanda e vuoi
                          cercare in tutta la normativa
                          <!-- <button
                          (click)="faqFeedback(true, midx)"
                          class="btn btn-outline-primary me-2 ms-2"
                        >
                          Si
                        </button> -->
                          <button
                            (click)="faqFeedback(false, midx)"
                            class="btn btn-sm btn-outline-primary ms-2"
                            [disabled]="answering"
                          >
                            Clicca qui
                          </button>
                        </span>
                      </div>
                    </div>
                    <div *ngIf="message.content.content && message.faq">
                      <small class="text-secondary"
                        ><i class="bi bi-info-circle me-1"></i> Questa FAQ suggerita è stata contrassegnata come non
                        esaustiva.</small
                      >
                      <hr />
                      <h5 class="mb-2">Ricerca in tutta la normativa:</h5>
                    </div>
                    <!-- AI Answer -->
                    <div *ngIf="message.content">
                      <p class="mb-0">{{ message.content.content }}</p>
                    </div>
                    <div *ngIf="message?.documents?.length">
                      <hr />
                      <div class="mt-2">
                        <h5 class="uppercase">FONTI</h5>
                        <div
                          *ngFor="let document of message.documents"
                          class="bg-light p-2 shadow-sm mb-2"
                        >
                          <label class="mb-3">
                            <strong>{{ document.fileName }}</strong>
                          </label>
                          <div class="d-flex justify-content-between">
                            <div class="me-2">
                              <p class="mb-0">
                                <small class="me-1">Rif. Pagina:</small>
                                <small
                                  *ngFor="
                                    let page of document.pageNumbers;
                                    index as pidx
                                  "
                                >
                                  <strong>{{ page }}</strong>
                                  <ng-container
                                    *ngIf="
                                      pidx + 1 < document.pageNumbers.length
                                    "
                                    >,
                                  </ng-container>
                                </small>
                              </p>
                            </div>
                            <div class="d-flex align-items-center">
                              <div class="me-4">
                                <small
                                  class="py-1 px-2 rounded-pill bg-white text-uppercase"
                                  >{{ document.extension || "pdf" }}</small
                                >
                              </div>
                              <button
                                class="btn btn-sm btn-secondary mr-1 text-nowrap"
                              >
                                <i class="bi bi-search"></i>
                                Anteprima
                              </button>
                              <button
                                class="btn btn-outline-dark btn-sm ms-2 text-nowrap"
                              >
                                <i class="bi bi-download"></i>
                                Download
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="midx > 0" class="ms-1">
                    <div>
                      <button
                        [triggers]="'hover'"
                        [placement]="'left'"
                        [ngbTooltip]="
                          answerFeedback(message.question_id) === true
                            ? 'Riposta contrassegnata come corretta'
                            : 'Segna risposta come corretta'
                        "
                        (click)="provideFeedback(message.question_id, true)"
                        class="btn btn-sm btn-flat"
                      >
                        <i
                          *ngIf="
                            answerFeedback(message.question_id) === undefined ||
                            answerFeedback(message.question_id) === false
                          "
                          class="text-secondary bi bi-hand-thumbs-up h4"
                        ></i>
                        <i
                          *ngIf="answerFeedback(message.question_id) === true"
                          class="text-success bi bi-hand-thumbs-up-fill h4"
                        ></i>
                      </button>
                    </div>
                    <div>
                      <button
                        [triggers]="'hover'"
                        [placement]="'left'"
                        [ngbTooltip]="
                          answerFeedback(message.question_id) === false
                            ? 'Riposta contrassegnata come non corretta'
                            : 'Segna risposta come non corretta'
                        "
                        (click)="provideFeedback(message.question_id, false)"
                        class="btn btn-sm btn-flat"
                      >
                        <i
                          *ngIf="
                            answerFeedback(message.question_id) === undefined ||
                            answerFeedback(message.question_id) === true
                          "
                          class="text-secondary bi bi-hand-thumbs-down h4"
                        ></i>
                        <i
                          *ngIf="answerFeedback(message.question_id) === false"
                          class="text-danger bi bi-hand-thumbs-down-fill h4"
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="message.isUser">
                <div class="d-flex justify-content-end mb-4">
                  <div
                    class="message bg-secondary text-white py-2 px-4 rounded shadow-sm w-75"
                  >
                    <small
                      [ngbTooltip]="formattedThreadTime(message.date)"
                      class="text-light"
                      >Tu, {{ humanizedThreadTime(message.date) }}</small
                    >
                    <div>
                      {{ message.content }}
                    </div>
                  </div>
                  <div class="avatar-container ms-2">
                    <div
                      class="avatar bg-secondary d-flex justify-content-center align-items-center"
                    >
                      <i class="text-white bi bi-person-fill"></i>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="chat-bubble" *ngIf="answering">
              <div class="typing">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>
            </div>
          </div>
          <div class="chat-footer p-4 d-flex">
            <textarea
              #userInput
              [disabled]="answering"
              (keyup.enter)="ask()"
              (keydown.enter)="onKeydown($event)"
              [(ngModel)]="userMessage"
              class="form-control"
              placeholder="Il tuo messaggio..."
              textarea
            ></textarea>
            <button
              [disabled]="!userMessage"
              (click)="ask()"
              class="btn-rounded-full mb-0 btn btn-primary ms-3 px-4"
            >
              <i class="bi bi-send-fill"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="container pb-2 mt-4">
	<h2>
		<i class="bi bi-chat-right-text-fill"></i> {{tenant?.name}} <span *ngIf="focosOnSubject"> - {{selectedSubject?.name}}</span>
	</h2>
	<div class="card mt-4">
		<div class="card-body">
            <div class="form-group mb-2">
                <div class="form-group mb-4 mt-4" *ngIf="!focosOnSubject">
                    <label class="mb-2 fw-bold text-primary">Soggetto</label>
                    <select class="form-control" [(ngModel)]="selectedSubjectId">
                        <option *ngFor="let subject of subjects" [value]="subject.subject_id">
                            {{ subject.name }}
                        </option>
                    </select>
                </div>
                <div class="form-group mb-4 mt-4" [hidden]="true">
                    <label class="mb-2 fw-bold text-primary">Engine</label>
                    <select class="form-control" [(ngModel)]="selectedAIEngine">
                        <option *ngFor="let ai_engine of ai_engines" [value]="ai_engine.id">
                            {{ ai_engine.name }}
                        </option>
                    </select>
                </div>
                <div class="form-group mb-4">
				    <label class="mb-2 fw-bold text-primary">La tua domanda</label>
                    <textarea
                        [(ngModel)]="questionContent"
                        placeholder="es. Quali sono i fattori di rischio elevato?"
                        class="form-control"
                        rows="6"
                    ></textarea>
                </div>
                <div class="mt-4 mb-2 d-flex justify-content-between">
                    <div>
                        <button
                            [disabled]="!selectedSubjectId || !questionContent || answering || !selectedAIEngine"
                            class="btn btn-primary"
                            (click)="ask()"
                        >
                            <i class="bi-send"></i> Invia la richiesta
                        </button>
                    </div>
                </div>
                <div class="mt-4 form-group mb-4" [hidden]="!answering">
                    <div><label class="mb-2 fw-bold text-primary">Sto cercando la risposta...</label></div>
                </div>
                <div class="mt-4 form-group mb-4" *ngIf="answer && answer.content">
                    <div><label class="mb-2 fw-bold text-primary">Risposta</label></div>
                    <div>
                        <label class="mb-2 fw-bold">{{answer.content}}</label>
                    </div>
                    <div>
                        <label class="mb-2 fw-bold">Source {{answer.sources}}</label>
                    </div>
                </div>
			</div>
        </div>
    </div>
</div> -->
