import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { AdminComponent } from './admin/admin.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { appConfig } from './app.config';
import { ChatComponent } from './chat/chat.component';
import { CallbackComponent } from './callback/callback.component';
import { OAuthModule } from 'angular-oauth2-oidc';
/*
import { AuthService } from '../service/auth.service';
import { AuthInterceptor } from '../interceptor/auth.interceptor';
*/

@NgModule({
  declarations: [
    AppComponent,
    LeftMenuComponent,
    AdminComponent,
    ChatComponent,
    CallbackComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    NgSelectModule,
    OAuthModule.forRoot(),
  ],
  providers: [
    provideClientHydration(),
    appConfig.providers,
    /*AuthService,
    {
      provide: APP_INITIALIZER,
      useFactory: (initialAuthService: AuthService) => () => initialAuthService.initAuth(),
      deps: [AuthService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },*/
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }