import { AnswerSource } from "./AnswerSource";
import { Faq } from "./Faq";

export class DocumentSnippet {
  filename: string = '';
  pagenumber: string = '';
  snippet: string = '';
  snippet_summary: string = '';
}

export class DocumentChunk {
  filename: string = '';
  content: string = '';
  page: string = '';
  subject_id: string = '';
  tenant_id: string = '';
}

export class Answer {
  content!: string
  sources!: Array<AnswerSource>
  relevant_document_snippets!: Array<DocumentSnippet>;
  chunks!: Array<DocumentChunk>;
  thread_id!: string;
  question_id!: string;
  faq?: Faq;
}