import { Component, ElementRef, ViewChild } from '@angular/core';
import { MainService } from '../../service/main.service';
import { ActivatedRoute } from '@angular/router';
import { Tenant } from '../../model/Tenant';
import { Subject } from '../../model/Subject';
import { Question } from '../../model/Question';
import { Answer, DocumentSnippet } from '../../model/Answer';
import { Faq } from '../../model/Faq';
import { Thread } from '../../model/Thread';
import { ThreadHistory } from '../../model/ThreadHistory';
import { LocalStorageService } from 'ngx-localstorage';
import moment from 'moment/min/moment-with-locales';

export class AnswerFeedback {
  id: string = '';
  feedback: boolean | null = null;
}

export class BotMessage {
  id: string = '';
  content: Answer | null = null;
  date: string = '';
  documents: Array<Document> = [];
  isUser: boolean = false;
  isBot: boolean = true;
  thread_id: string = '';
  question_id: string = '';
  faq: Faq | null = null;
}

export class UserMessage {
  id: string = '';
  content: string = '';
  date: string = '';
  isUser: boolean = true;
  isBot: boolean = false;
}

export class Document {
  id: string = '';
  fileName: string = '';
  pageNumbers: Array<string> = [];
  extension: string = '';
}

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent {
  @ViewChild('scrollMe')
  private myScrollContainer!: ElementRef;
  @ViewChild('userInput')
  private userInput!: ElementRef;
  tenant: Tenant | null = null;
  subjects: Subject[] | null = null;
  selectedSubject: Subject | null = null;
  selectedSubjectId: string | null = 'all';
  questionContent: string | null = null;
  answer: Answer = new Answer();
  answering: boolean = false;
  gettingChatList: boolean = false;
  gettingThread: boolean = false;
  focosOnSubject = false;
  userId: string = 'thisuserid';
  current_thread_id: string = '';
  responseFeedback: Array<AnswerFeedback> = [];
  ai_engines = [
    {
      id: 'groq',
      name: 'Groq',
    },
    {
      id: 'gpt-3.5-turbo-0125',
      name: 'GPT 3.5 Turbo 0125',
    },
    {
      id: 'gpt-4o',
      name: 'GPT 4o',
    },
  ];
  ai_model = 'groq';
  ai_models = [
    {
      id: 'groq',
      name: 'LLaMA3 70b',
    },
    {
      id: 'gpt-3.5-turbo-0125',
      name: 'GPT 3.5 Turbo 0125',
    },
    {
      id: 'gpt-4o',
      name: 'GPT 4o',
    },
  ];
  selectedAIEngine: string | null = this.ai_engines[0]['id'];
  userMessage: string = '';
  messages: Array<any> = [];
  threads: Array<Thread> = [];
  highlightMessageIdx: number = -1;

  constructor(
    private _mainService: MainService,
    private activatedRoute: ActivatedRoute,
    private localStorage: LocalStorageService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
			Object.keys(params).forEach((param) => {
				if(param === 'user') {
          this.userId = params[param];
        }
			});
		});

    const tenant_id = this.activatedRoute.snapshot.params['tenant_id'];
    const subject_id = this.activatedRoute.snapshot.params['subject_id'];
    if (subject_id) {
      this.selectedSubjectId = subject_id;
      this.focosOnSubject = true;
    } else {
      this.focosOnSubject = false;
    }
    this._mainService.getModelList().subscribe((model_list) => {
      this.ai_engines = model_list;
    });
    this._mainService.getTenant(tenant_id).subscribe((tenant) => {
      if (tenant) {
        this.tenant = tenant;
        this.getSubjects();
        this.getThreads();
      } else {
        console.warn('No tenant for giving tenant_id ', tenant_id);
      }
    });

    this.addFirstBotMessage();
    if (this.localStorage.get('responseFeedback')) {
      this.responseFeedback = this.localStorage.get('responseFeedback') || [];
    } else {
      this.localStorage.set('responseFeedback', this.responseFeedback);
    }
  }

  addFirstBotMessage() {
    let botMsg = new BotMessage();
    botMsg.content = new Answer();
    botMsg.content.content =
      'Fai una domanda per cercare all\'interno delle FAQ e dell\'intera normativa';
    botMsg.date = new Date().toString();
    this.messages.push(botMsg);
  }

  getSubjects(): void {
    if (!this.focosOnSubject) {
      this.selectedSubjectId = 'all';
    }
    if (this.tenant && this.tenant.tenant_id) {
      this._mainService
        .getSubjects(this.tenant.tenant_id)
        .subscribe((subjects) => {
          if (subjects) {
            this.subjects = [];
            this.subjects.push({
              name: 'Tutti gli ambiti',
              prompt: '',
              subject_id: 'all',
              tenant_id: this.tenant?.tenant_id || '',
            });
            this.subjects = this.subjects.concat(subjects);

            if (!this.selectedSubjectId) {
              this.selectedSubjectId = 'all';
            }
            if (this.focosOnSubject && this.subjects) {
              this.selectedSubject =
                this.subjects.find(
                  (subject) => subject.subject_id === this.selectedSubjectId
                ) || null;
            }
          } else {
            console.warn(
              'No subjects for giving tenant_id ',
              this.tenant?.tenant_id
            );
          }
        });
    }
  }

  onSubjectChange(event: Event) {
    const selectedIndex = (event.target as HTMLSelectElement).selectedIndex;
    const selectedSubject = this.subjects ? this.subjects[selectedIndex] : null;
    if (selectedSubject) {
      this.selectedSubjectId = selectedSubject.subject_id;
    }
  }

  threadSubject(thread: Thread ){
    return this.subjects?.find(
      (sbj: any) => sbj.subject_id === thread.subject_id
    )?.name || this.subjects?.find(
      (sbj: any) => sbj.subject_id === 'all'
    )?.name
  }

  getThreads() {
    this.gettingChatList = true;
    this._mainService
      .threadList(this.tenant?.tenant_id, this.userId)
      .subscribe({
        next: (threads) => {
          if (threads) {
            this.threads = threads;
          } else {
          }
        },
        error: (e) => {},
        complete: () => {
          this.gettingChatList = false;
        },
      });
  }

  ask(message?: string, question_id?: string) {
    let userMsgString = this.userMessage || message;
    if (!userMsgString || userMsgString.trim() === '') {
      return;
    }

    if (
      this.tenant &&
      this.tenant.tenant_id &&
      this.ai_model &&
      (userMsgString) &&
      this.selectedAIEngine
    ) {

      let botMsg = new BotMessage();
      this.answer = new Answer();

      const hasThreadId = this.current_thread_id;
      this.answering = true;

      let userMsg = new UserMessage();
      userMsg.content = userMsgString;
      userMsg.date = new Date().toString();
      if(!question_id){
        this.messages.push(userMsg);
      }
      setTimeout(() => {
        this.scrollToBottom();
      }, 100);
      const question = new Question();
      question.body = userMsgString;
      question.user_id = this.userId;
      question.model = this.ai_model;
      question.thread_id = this.current_thread_id;

      if(message){
        question.question_id = question_id;
      }

      // if(this.messages.slice(-1)){}


      this._mainService
        .ask(
          this.tenant.tenant_id,
          this.selectedSubjectId,
          question,
          this.ai_model
        )
        .subscribe({
          next: (answer) => {
            if (answer) {


              this.answer = answer;

              //Adding questionId to previous question
              this.messages[this.messages.length-1].question_id = answer.question_id;
              this.messages[this.messages.length-1].thread_id = answer.thread_id;

              botMsg.content = JSON.parse(JSON.stringify(this.answer));
              botMsg.documents = this.documentsStructured(answer);
              botMsg.thread_id = answer.thread_id;
              botMsg.question_id = answer.question_id;
              botMsg.faq = answer.faq || null;
              botMsg.date = new Date().toString();
              let existingquestionIdx = this.messages.findIndex(
                (message: any) => message.question_id === answer.question_id && message.isBot
              );

              if(existingquestionIdx > -1) {
                //Answer doesn't, updating it
                this.messages[existingquestionIdx] = botMsg;
                this.highlightMessage(this.messages.length-1);
              } else {
                // Adding answer
                this.messages.push(botMsg);
              }


              this.current_thread_id = answer.thread_id;
              this.userMessage = '';
              if (!hasThreadId) {
                this.getThreads();
              }
              setTimeout(() => {
                this.scrollToBottom();
              }, 100);
            } else {
              console.warn('No answer');
            }
          },
          error: (e) => {},
          complete: () => {
            this.answering = false;
          },
        });
    }
  }

  documentsStructured(answer: Answer) {
    let documents: Array<Document> = [];
    if (answer?.relevant_document_snippets) {
      answer?.relevant_document_snippets.forEach(
        (docSnippet: DocumentSnippet) => {
          let existingDocumentIndex = documents.findIndex(
            (document: Document) => document.fileName === docSnippet.filename
          );
          if (existingDocumentIndex > -1) {
            //Document already added
            if (
              documents[existingDocumentIndex].pageNumbers.indexOf(
                docSnippet.pagenumber
              ) < 0
            ) {
              documents[existingDocumentIndex].pageNumbers.push(
                docSnippet.pagenumber
              );
            }
          } else {
            //Document not already added
            let doc = new Document();
            doc.fileName = docSnippet.filename;
            doc.pageNumbers.push(docSnippet.pagenumber);
            documents.push(doc);
          }
        }
      );
    }

    return documents;
  }

  documentsThreadStructured(answerQuestion: ThreadHistory) {
    let documents: Array<Document> = [];
    if (answerQuestion?.files) {
      answerQuestion.files.forEach(
        (file: any) => {
          let existingDocumentIndex = documents.findIndex(
            (document: Document) => document.fileName === file.file_name
          );
          if (existingDocumentIndex > -1) {
            //Document already added
            if (
              documents[existingDocumentIndex].pageNumbers.indexOf(
                file.page
              ) < 0
            ) {
              documents[existingDocumentIndex].pageNumbers.push(
                file.page
              );
            }
          } else {
            //Document not already added
            let doc = new Document();
            doc.fileName = file.file_name;
            doc.pageNumbers.push(file.page);
            documents.push(doc);
          }
        }
      );
    }

    return documents;
  }

  showThread(threadToShow: Thread) {
    this.gettingThread = true;
    this.messages = [];
    this._mainService
      .thread(this.tenant?.tenant_id, this.userId, threadToShow.thread_id)
      .subscribe({
        next: (thread: Array<ThreadHistory>) => {
          if (thread) {
            this.current_thread_id = threadToShow.thread_id;
            this.selectedSubjectId = threadToShow.subject_id || 'all';
            thread.reverse().forEach((answerQuestion) => {
              let userMsg = new UserMessage();
              userMsg.content = answerQuestion.question;
              userMsg.date = answerQuestion.time;

              this.messages.push(userMsg);

              let botMsg = new BotMessage();
              botMsg.content = new Answer();
              botMsg.content.content = answerQuestion.answer;
              botMsg.question_id = answerQuestion.question_id;
              botMsg.date = answerQuestion.time;
              botMsg.faq = answerQuestion.faq || null;
              botMsg.thread_id = threadToShow.thread_id || 'all';
              // Popola l'array files con nome del file e pagina

              if (answerQuestion.files) {
                botMsg.documents = this.documentsThreadStructured(answerQuestion);
              }
              this.messages.push(botMsg);
            });
            setTimeout(() => {
              this.scrollToBottom(true);
            }, 100);
          } else {
          }
        },
        error: (e) => {},
        complete: () => {
          this.gettingThread = false;
        },
      });
  }

  formattedThreadTime(time: string) {
    return moment(time).format('DD MMM YYYY HH:mm');
  }

  humanizedThreadTime(time: string) {
    return moment(time).locale('it').fromNow();
    // return moment(time).format('DD MMM YYYY HH:mm').fromNow();
  }

  scrollToBottom(fast?: boolean): void {
    try {
      let behavior = fast ? 'instant' : 'smooth';
      this.myScrollContainer.nativeElement.scrollTo({
        top: this.myScrollContainer.nativeElement.scrollHeight,
        behavior: behavior,
      });
      // this.myScrollContainer.nativeElement.scrollTop = ;
    } catch (err) {}
  }

  newThread() {
    this.current_thread_id = '';
    this.selectedSubjectId = 'all';
    this.messages = [];
    this.addFirstBotMessage();
  }

  onKeydown(event: any) {
    event.preventDefault();
  }

  provideFeedback(questionId: string, feedback: boolean) {
    let feedbackIdx = this.responseFeedback.findIndex(
      (fdb: AnswerFeedback) => fdb.id === questionId
    );
    if (feedbackIdx > -1) {
      //Feedback exist
      if (this.responseFeedback[feedbackIdx].feedback === feedback) {
        //Feedback equal to value, toggle (remove) feedback
        this.responseFeedback.splice(feedbackIdx, 1);
      } else {
        //Feedback non equal, changing the feedback
        this.responseFeedback[feedbackIdx] = {
          id: questionId,
          feedback: feedback,
        };
      }
    } else {
      this.responseFeedback.push({ id: questionId, feedback: feedback });
    }
    this.localStorage.set('responseFeedback', this.responseFeedback);
  }

  answerFeedback(questionId: string) {
    let feedback = this.responseFeedback.find(
      (fdb: AnswerFeedback) => fdb.id === questionId
    )?.feedback;
    return feedback;
  }

  faqFeedback(feedback: boolean, messageIdx: number){
    let msg = this.messages[messageIdx];
    if(feedback) {
      msg.feedback = true;
    } else {
      msg.feedback = false;

      this.ask(this.messages[messageIdx-1].content, msg.question_id);
    }
  }

  highlightMessage(messageIdx: number){
    this.highlightMessageIdx = messageIdx;
    setTimeout(() => {
      this.highlightMessageIdx = -1;
    }, 500);
  }

}
